<template>
    <div>
        <el-dialog :title="$t('newadd.t.输入代付用户')" :visible.sync="visible">
            <div>
                <div> {{$t('orderCenter.orderSn')}}{{ orderId }}</div>
                <div style="margin:10px 0">{{$t('newadd.t.选择支付方式')}}</div>
                <el-button @click="testPay">测试支付</el-button>
                <el-button @click="wxPay">  {{$t('newadd.t.微信支付')}} </el-button>
				<el-button @click="alipay">  {{$t('newadd.t.支付宝支付')}}</el-button>
                <el-button @click="payPal">PayPal</el-button>
				<el-button @click="payAccount">  {{$t('newadd.t.对公账户')}}</el-button>
				<el-button @click="wantpay">{{$t('newadd.t.中国银联')}}</el-button>
            </div>
			
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>

        <test-pay-dialog ref="testPayDialog" @ok="ok"/>
        <wx-pay-dialog ref="wxPayDialog" @ok="ok"/>
        <pay-pal-pay-dialog ref="payPalPayDialog" @ok="ok"/>
		<alipay ref="alipayPalPayDialog"></alipay>
    </div>
</template>

<script>
import WxPayDialog from "./WxPayDialog";
import TestPayDialog from "./TestPayDialog";
import PayPalPayDialog from "./PayPalPayDialog";
import {ccbPay} from "../api/order";
import alipay from "./alipayDialog";

export default {
    name: "PayDialog",
    components: {PayPalPayDialog, WxPayDialog, TestPayDialog,alipay},
    data() {
        return {
            visible: false,
            orderId: null,
			isReaplce:false
        }
    },
    methods: {
		 async wantpay(){
			let url=await ccbPay(this.orderId);
			  document.querySelector('body').innerHTML=url;
			  document.forms[0].submit()
		},
		ChangeReaplce(e){
			this.visible=false
		},
        show(orderId) {
            console.log("orderId", orderId)
            this.orderId = orderId;
            this.visible = true;
        },
        cancel() {
            this.visible = false;
        },
        ok() {
            this.visible = false;
            this.$emit("ok")
		
        },
        testPay() {
            this.$refs.testPayDialog.show(this.orderId);
			
        },
        wxPay() {
            this.$refs.wxPayDialog.show(this.orderId);
        },
        payPal() {
            this.$refs.payPalPayDialog.show(this.orderId);
        },
		alipay(){
			this.$refs.alipayPalPayDialog.show(this.orderId);
			
		},
		payAccount(){
			this.$router.push({ path: '/article?id=16'  })
			
		}
    }
}
</script>

<style scoped>

</style>