<template>
	<div class="body">
		<!-- <navTop></navTop> -->
		<div class="wrap">

			<div style="display: flex;justify-content: space-between;">

				<div class="wrap-left">
					<orderAdminLeft :active="menuIndex" v-on:changeOrderStatus="orderStatusChange"></orderAdminLeft>
				</div>

				<div class="wrap-right">
					<el-card :body-style="{height: '700px',overflowY:'scroll'}">
						<div class="" v-for="(order,i) in list" :key="i">
							<div class="order-list-header">
								<div>{{ $t("module." + order.module) }}</div>
								<div>{{ order.createTime }}</div>
							</div>
							<el-table :data="order.orderGoodsList">
								<el-table-column :label="$t('userCenter.goodsInfo')">
									<template v-slot="scope">
										<el-image style="width: 40px; height: 40px"
											:src="$req.getFileUrl(scope.row.img)" fit="fit">
										</el-image>
									</template>
								</el-table-column>
								<el-table-column :label="$t('applyInvoice.type')">
									<template v-slot="scope">
										{{ $t("module." + scope.row.module) }}
									</template>
								</el-table-column>
								<el-table-column :label="$t('userCenter.price')">
									<template v-slot="scope">
										{{ $transformMoney( scope.row.uniMoney,order.currency) }}
									</template>
								</el-table-column>

								<el-table-column :label="$t('userCenter.number')">
									<template v-slot="scope">
										{{ scope.row.num }}
									</template>
								</el-table-column>


							</el-table>

							<table class="tab-header  " border="0" cellpadding="0" cellspacing="0">
								<tr style="height: 40px;">
									<td style="background-color: #eee;color:#888;" width="50">
										<el-checkbox @change="signChange" v-model="order.checked"
											style="margin-left: 25px;"
											:disabled="order.status == 10 || order.status == 80 ? false:true">
											{{ $t('operation.select') }}
										</el-checkbox>
									</td>
									<!-- 查看详情 -->
									<td style="background-color: #eee;color:#888;">
										<span style="margin:0 90px;cursor: pointer;"
											@click="$refs.orderDetail.show(order.id)" v-if="order.module!==101">
											{{ $t('applyInvoice.getOrderDetail') }}
										</span>
									</td>
									<td style="background-color: #eee;color:#888;">
										<span
											style="color: #888;margin-right:5px;">{{ $t('applyInvoice.subtotal') }}</span>
										<span
											style="color:red;font-size: 16px;">{{ $transformMoney( order.money,order.currency )}}</span>
									</td>

									<td style="background-color: #eee;color:#888;">

										<el-button type="primary" size="mini" @click="orderDel()"
											v-if=" arr.indexOf(order.status) != -1">{{ $t('orderCenter.orderDel') }}
										</el-button>
										<el-button type="primary" size="mini" @click="orderDel()"
											v-if=" order.status == 2 ">{{ $t('orderAdmin.refundOrder') }}</el-button>
										<el-link style="color:red;font-size: 12px;"
											v-if="order.status == 70 || order.status == 81">
											{{ $t('orderCenter.remindDelivery') }}
										</el-link>
										<el-button type="primary" size="mini" @click="orderDel()"
											v-if=" order.status === 90 ">{{ $t('orderAdmin.confirmReceive') }}
										</el-button>
									</td>

									<td
										style="background-color: #eee;height: 40px;display: flex;padding: 10px 0;justify-content: flex-end;">
										<!-- 状态，0待用户确认，10待支付一笔款，11已支付一笔款，20已确认，30已排产，40物料已到位，50生产制作完成，60质量检验完成, -->
										<!-- 70准备发货，80待支付二笔款（准备发货），81已支付二笔款（准备发货），90已发货，100已完成，200已取消 -->
										<el-dropdown split-button type="danger" @command="command"
											@click="go_sentInvoice(order)" v-if="order.status !== 10  "
											style="margin-right: 10px;">
											{{ $t('newadd.t.申请开票') }}
											<el-dropdown-menu slot="dropdown">
												<el-dropdown-item command="a">{{ $t('newadd.t.我要修改') }}
												</el-dropdown-item>
											</el-dropdown-menu>
										</el-dropdown>
										<el-button v-if="order.status === 10 || order.status === 80" type="warning
									" size="mini" @click="$refs.elsepayDialog.show(order.id)">
											{{ $t('orderAdmin.elsePay') }}
										</el-button>
										<el-button v-if="order.status === 10 || order.status === 80" type="primary"
											size="mini" @click="$refs.payDialog.show(order.id)">
											{{ $t('orderAdmin.clickPay') }}
										</el-button>
										<el-button
											v-if="order.status == 100 &&  !order.orderGoodsList[0].commented && (order.module==1 || order.module==201 || order.module==301) "
											type="primary" size="mini"
											@click="$refs.commentDialog.show(order.id,order.module,order.orderGoodsList[0].goodsId)">
											{{ $t('orderCenter.clickComment') }}
										</el-button>
										<el-button type="success" size="mini"
											@click="$router.push('/chat/index?iskefu=true')">
											{{ $t('orderCenter.kefu') }}
										</el-button>
										<el-button type="info" size="mini" v-if="order.status==100"
											@click='complain(order)'>
											{{ $t('orderCenter.complain') }}
										</el-button>
										<p v-if="order.status == 70 || order.status == 81">{{ $t("orderCenter.step4") }}
										</p>
									</td>

								</tr>
							</table>

						</div>



					</el-card>
					<div style="padding: 10px;" v-is="list.length">

						<Pagination :Pinfo="Pinfo" @pageination="get" />
					</div>
				</div>

			</div>



			<div class="floor">
				<div class="floor-left">
					<span>
						<el-checkbox v-model="allSelect" @change="allchange">全选</el-checkbox>
					</span>

				</div>
				<div class="floor-right">
					<span>已选<em>{{ selectNum }}</em>件商品</span>
					<span>小计:<em style="color:red;">{{$transformMoney(total,this.userInfo.currency)}}</em></span>
					<span>
						<el-button type="primary" @click="allprimary">结算订单</el-button>
					</span>
				</div>
			</div>
		</div>


		<commentDialog ref="commentDialog"></commentDialog>
		<orderDetail ref="orderDetail"></orderDetail>
		<elsepay-dialog ref="elsepayDialog" />
		<pay-dialog ref="payDialog" @ok="reload" />

	</div>
</template>
<script>
	import orderAdminLeft from "@/components/OrderAdminLeft";
	import navTop from "@/components/navTop";
	import PayDialog from "../../components/PayDialog";
	import elsepayDialog from "../../components/elsepayDialog.vue";
	import orderDetail from "../../components/orderDetail.vue";
	import commentDialog from '../../components/commentDialog.vue';

	import bigDecimal from "js-big-decimal";
	import Pagination from '@/components/pagination';

	import {
		mapState
	} from "vuex";

	import {
		list,
		orderInvoice
	} from "../../api/order";

	export default {
		components: {
			PayDialog,
			orderAdminLeft,
			navTop,
			Pagination,
			elsepayDialog,
			orderDetail,
			commentDialog
		},
		computed: {
			...mapState(['userInfo'])
		},
		data() {
			return {
				Pinfo: {

				},
				ptotal: 0,
				currentPage: 1,
				pSize: 0,
				menuIndex: '0',
				orderStatus: 0,
				allSelect: false,
				arr: [1, 3, 4, 7],
				arrStatus: [1, 2, 3, 4],
				selectNum: 0,
				total: 0,
				fd: {
					size: 3,
					current: 1,
					module: null, //所属模块，不传默认全部，多个用逗号分隔，1代销品，101设计点，201微通道平行流-标准品，202微通道平行流-自己设计，203微通道平行流-平台设计，301微通道蛇形管-标准品，302微通道蛇形管-自己设计，303微通道蛇形管-平台设计，其它待定
					what: null, //查询哪些订单，self自己的，replacePay代付的，all全部，默认all
					status: null, //状态，不传默认全部，多个用逗号分隔，0待用户确认，10待支付一笔款，11已支付一笔款，20已确认，30已排产，40物料已到位，50生产制作完成，60质量检验完成，70准备发货，80待支付二笔款（准备发货），81已支付二笔款（准备发货），90已发货，100已完成，200已取消
				},
				list: []
			}
		},
		created() {
			this.getList();
		},
		methods: {
			command(e) {
				if (e == 'a') {
					this.$router.push("/sentInvoice")
				}

			},
			// 去开票

			go_sentInvoice(item) {

				orderInvoice(item).then(res => {
					this.$message({
						message: "开票成功",
						type: "success",
					})
				})

			},

			async allchange(e) {
				await this.check(e)

				this.jisuan()

			},
			// 单选
			signChange() {
				this.jisuan()
			},
			// 全选
			check(e) {
				return new Promise((reolve, reject) => {
					this.list.forEach(item => {
						if (item.status == 10 || item.status == 80) {
							this.$set(item, "checked", e)
							return item
						}
					})
					reolve(e)
				})

			},
			orderStatusChange(data) {
				this.fd = Object.assign({
					current: 1,
					size: 3
				}, data);
				this.getList();
			},

			// 投诉
			complain(item) {
				this.$router.push({
					path: '/complain/complainIndex',
					query: {
						id: item.id
					}
				})

			},

			reload() {
				//重新加载列表
				this.getList()
			},
			// 计算
			jisuan() {
				var allPrice = 0;
				var selectNum = 0;
				for (var i = 0; i < this.list.length; i++) {
					if (this.list[i].status == 10 || this.list[i] == 80) {
						if (this.list[i].checked) {
							allPrice = bigDecimal.add(allPrice, this.list[i].money);
							selectNum += this.list[i].checked;
						}
					}
				}
				this.total = allPrice;
				this.selectNum = selectNum;
			},
			async getList() {
				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});

				let res = await list(this.fd);
				res.records.forEach(order => {

					order.orderGoodsList.forEach(goods => goods.goodsMirror = JSON.parse(goods.goodsMirror ||
						"{}"))
				})
				this.list = res.records;


				this.Pinfo = res;


				loading.close();
			},


			get(e) {
				this.fd.current = e.currentPage
				this.fd.size = e.pageSize
				this.getList()
			},
			next() {
				this.fd.current++;
				this.getList();
			},
			// 检测orderID
			checkID() {
				var arrID = this.list.filter(v => v.checked).map(v => v.id).join(",");
				return arrID
			},
			allprimary() {
				var arrID = this.checkID();
				if (!arrID) {
					this.$message('请选择订单')
					return
				}
				this.$refs.payDialog.show(arrID)
			}
		}
	}
</script>
<style scoped>
	.body {
		padding-top: 80px;
		background: #f1f1f1;
		height: 100vh;
	}

	.wrap {
		margin: 0 auto;
		width: 1400px;

	}

	.wrap-left {
		font-size: 14px;
		width: 20%;

		background-color: #fff;
		padding: 20px 15px 40px;
	}

	.wrap-right {
		width: 78%;

		background-color: #fff;


	}

	.order-list-header {
		display: flex;
		justify-content: space-between;
		background-color: #555;
		color: #fff;
		padding: 10px 30px;
		font-size: 14px;
	}

	.tab-header {
		width: 100%;
	}

	.tab-header tr td {
		font-size: 14px;
		text-align: center;
		color: #888;
	}

	.floor {
		display: flex;
		justify-content: space-between;
		background: #FFFFFF;
		align-items: center;
		padding: 20px 0;
		margin-top: 20px;
	}

	.floor-left {}

	.floor-left span {
		margin-left: 20px;
	}

	.floor-right span {
		margin-right: 20px;
		font-size: 14px;
	}

	.floor-right span em {
		font-style: normal;
		color: red;
		margin: 0 10px;
		font-size: 18px;
	}
</style>
